import React, { useState } from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Button from "../common/Button";
import { useAuth } from "../../middlewares/Auth/AuthContext";
import { SignupPayload } from "./services/signup.service";
import { signupService } from "./services";

interface SignupFormProps {
  handleSuccessfulSignup: (email: string) => void;
}

interface SignupFormInitalValuesProp {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  organization_name: string;
  send_promotional_emails: boolean;
}

export default function SignupForm({
  handleSuccessfulSignup,
}: SignupFormProps) {
  const auth = useAuth();

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["signup"],
    mutationFn: (values: SignupPayload) => signupService.signup(values),
    onSuccess(_response: any, values: SignupPayload) {
      handleSuccessfulSignup(values.email);
    },
    onError(error: AxiosError) {
      if (error.response?.status === 401) {
        auth.logout();
      }
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (values: SignupFormInitalValuesProp) => {
    const formObject = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      organization_name: values.organization_name,
      send_promotional_emails: values.send_promotional_emails,
    };
    await mutateAsync(formObject);
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  if (isPending) {
    return (
      <Box
        sx={{ display: "flex", marginTop: "64px", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        organization_name: "",
        send_promotional_emails: false,
      }}
      validationSchema={Yup.object({
        first_name: Yup.string().required("Required"),
        last_name: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string()
          .min(8, "Password must be at least 8 characters")
          .required("Required"),
        confirm_password: Yup.string()
          .oneOf([Yup.ref("password")], "Passwords must match")
          .required("Required"),
        organization_name: Yup.string(),
        send_promotional_emails: Yup.boolean(),
      })}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {(formik) => (
        <Box
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            {/* First Name Field */}
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                label="First Name"
                autoComplete="first_name"
                autoFocus
                error={!!formik.errors.first_name}
                helperText={formik.errors.first_name}
                {...formik.getFieldProps("first_name")}
              />
            </Grid>
            {/* Last Name Field */}
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                label="Last Name"
                autoComplete="last_name"
                autoFocus
                error={!!formik.errors.last_name}
                helperText={formik.errors.last_name}
                {...formik.getFieldProps("last_name")}
              />
            </Grid>
            {/* Email Field */}
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label="Email Address"
                autoComplete="email"
                autoFocus
                error={!!formik.errors.email}
                helperText={formik.errors.email}
                {...formik.getFieldProps("email")}
              />
            </Grid>
            {/* Password Field */}
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label="Password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                error={!!formik.errors.password}
                helperText={formik.errors.password}
                {...formik.getFieldProps("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* Confirm Password Field */}
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label="Confirm Password"
                type="password"
                autoComplete="confirm-password"
                error={!!formik.errors.confirm_password}
                helperText={formik.errors.confirm_password}
                {...formik.getFieldProps("confirm_password")}
              />
            </Grid>
            {/* Organization Name Field */}
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label="Organization Name"
                autoFocus
                error={!!formik.errors.organization_name}
                helperText={formik.errors.organization_name}
                {...formik.getFieldProps("organization_name")}
              />
            </Grid>
            {/* Send Promotional Emails Checkbox */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    {...formik.getFieldProps("send_promotional_emails")}
                    checked={formik.values.send_promotional_emails}
                  />
                }
                label="I want to receive news, offers, and helpful content via email."
              />
            </Grid>
          </Grid>
          {/* Sign Up Button */}
          <Button
            label="Sign Up"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
}
