import React from "react";

import Box from "@mui/material/Box";
import FactCheckSharp from "@mui/icons-material/FactCheckSharp";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import { Card } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import SidebarView from "../common/Sidebar";
import CardView from "./components/Card";
import EpisodesList from "./components/EpisodesList";
import { dashboardService } from "./services";

export default function DashboardView() {
  const globalTheme = useTheme();
  const mobile = useMediaQuery(globalTheme.breakpoints.down("md"));

  const { data } = useQuery({
    queryKey: ["dashboard-stats"],
    queryFn: () => dashboardService.fetchDashboardStats(),
  });

  const { data: projectData } = useQuery({
    queryKey: ["projects"],
    queryFn: () => dashboardService.fetchProjects(),
  });

  const px = mobile ? 2 : 4;

  return (
    <>
      <SidebarView pageName="Episodes" />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[50]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: `${mobile ? "column" : "row"}`,
            flexFlow: `${mobile ? "none" : "wrap"}`,
            boxSizing: "border-box",
            marginTop: 12,
            marginX: px,
            paddingTop: 0,
            justifyContent: "space-between",
            flexGrow: 0,
          }}
        >
          <CardView
            Icon={FactCheckSharp}
            title="Projects"
            value={data?.number_of_projects || 0}
            color="#1278c7"
            disabled={false}
          />
          <CardView
            Icon={HistoryToggleOffIcon}
            title="Total minutes"
            value={data?.total_episodes_length_in_minutes || 0}
            color="#ebac6e"
            disabled={false}
          />
          <CardView
            Icon={PodcastsIcon}
            title="Episodes"
            value={data?.number_of_episodes || 0}
            color="#63bf47"
            disabled={false}
          />
          <CardView
            Icon={FolderSharedIcon}
            title="Shared Episodes"
            value={data?.number_of_shared_projects || 0}
            color="#6ebbeb"
            disabled
          />
        </Box>
        <Card
          sx={{
            position: "relative",
            px,
            py: 2,
            marginY: 4,
            marginX: px,
            overflow: "visible",
          }}
        >
          <EpisodesList projects={projectData?.projects} />
        </Card>
      </Box>
    </>
  );
}
