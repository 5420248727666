import React from "react";

import { TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import { Column } from "../../../../common/Table/types";
import ProjectActionCell from "./ProjectActionCell";

interface TableListProps {
  columns: Column[];
  row: Record<string, any>;
}

interface TableCellProps {
  column: Column;
  value: string;
  projectId: string;
  projectName: string;
  projectDescription: string;
}

function ProjectCell({
  column,
  value,
  projectId,
  projectName,
  projectDescription,
}: TableCellProps): JSX.Element {
  const isLongText = column.id === "name" || column.id === "description";
  const rowValue =
    value &&
    value.length > 40 &&
    (column.id === "name" || column.id === "description")
      ? `${value.substring(0, 40)}...`
      : value;
  return (
    <TableCell key={column.id} align={column.align}>
      {column.id === "action" ? (
        <ProjectActionCell
          projectId={projectId}
          projectName={projectName}
          projectDescription={projectDescription}
        />
      ) : (
        <Tooltip
          title={isLongText && typeof value === "string" ? value : ""}
          arrow
          followCursor
        >
          <Typography sx={{ cursor: "context-menu" }}>
            {column.format && typeof value === "number"
              ? column.format(value)
              : rowValue}
          </Typography>
        </Tooltip>
      )}
    </TableCell>
  );
}

export default function ProjectRow({ columns, row }: TableListProps) {
  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      {columns.map((column: Column) => {
        const value = row[column.id];
        return (
          <ProjectCell
            key={column.id}
            column={column}
            value={value}
            projectId={row.id}
            projectName={row.name}
            projectDescription={row.description}
          />
        );
      })}
    </TableRow>
  );
}
