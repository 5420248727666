import React, { useEffect, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import BoltIcon from "@mui/icons-material/Bolt";
import { Box, Button, CircularProgress, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import ReplyIcon from "@mui/icons-material/Reply";

import AccountMenu from "./components/AccountMenu";
import PricingPlaneDialog from "../PricingPlansDialog";
import { usersService } from "./services";
import { convertSecondsToHoursMinutes } from "../../../utils/formatTIme";
import { useUserState } from "../../../store";
import calculateUsedTimeInPercentage from "../../../utils/calculateTimeUsage";

interface Props {
  open: boolean;
  toggleDrawer: () => void;
  pageName: string;
  hideToggle?: boolean;
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 260,
    width: `calc(100% - ${260}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("md")]: {
      overflowX: "hidden",
    },
  }),
}));

export default function Headerbar({
  toggleDrawer,
  open,
  pageName,
  hideToggle = false,
}: Props) {
  const [openPricingPlanDialog, setOpenPricingPlanDialog] = useState(false);
  const { updateUser } = useUserState();

  const handleOpenPricingPlanDialog = () => {
    setOpenPricingPlanDialog(!openPricingPlanDialog);
  };

  const { data, isSuccess } = useQuery({
    queryKey: ["authenticated-user"],
    queryFn: () => usersService.fetchAuthenticardUser(),
  });

  useEffect(() => {
    if (isSuccess && data) {
      updateUser(data);
    }
  }, [isSuccess, data, updateUser]);

  const availableTimeInSeconds =
    data?.organization.subscription
      ?.number_of_available_seconds_in_current_billing_cycle || 0;

  const totalMinutes =
    data?.organization.subscription?.pricing_plan?.features
      ?.number_of_minutes || 0;

  const percentageUsed = calculateUsedTimeInPercentage(
    totalMinutes,
    availableTimeInSeconds,
  );

  const { hours, minutes } = convertSecondsToHoursMinutes(
    availableTimeInSeconds,
  );

  const remainingTime = `${hours}:${minutes}`;

  const handleGoBackButton = () => {
    window.history.go(-1);
  };

  return (
    <AppBar
      position="absolute"
      open={open}
      sx={{
        backgroundColor: (theme) => theme.palette.grey[50],
        color: (theme) => theme.palette.grey[800],
        border: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        {hideToggle ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleGoBackButton}
            sx={{
              paddingX: 1,
              ":hover": {
                backgroundColor: (theme) => theme.palette.grey[50],
              },
              color: "#727ff2",
            }}
          >
            <ReplyIcon />
          </IconButton>
        ) : (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              paddingX: 2.5,
              ":hover": {
                backgroundColor: (theme) => theme.palette.grey[50],
              },
              ...(open && {
                display: "none",
              }),
            }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, ml: 4 }}
        >
          {pageName}
        </Typography>
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            marginRight: "10px",
          }}
        >
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            {/* Background circle */}
            <CircularProgress
              variant="determinate"
              value={100}
              sx={{
                color: (theme) => theme.palette.grey[400],
              }}
            />
            {/* Progress circle */}
            <CircularProgress
              variant="determinate"
              value={percentageUsed}
              sx={{
                color: (theme) => theme.palette.info.main,
                position: "absolute",
                left: 0,
              }}
            />
          </Box>
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tooltip
              title={`Remaining Time: ${hours} hour(s) and ${minutes} minute(s)`}
            >
              <Typography
                variant="caption"
                component="div"
                sx={{
                  color: (theme) => theme.palette.grey[800],
                  fontSize: "10px",
                  cursor: "help",
                }}
              >{`${remainingTime}`}</Typography>
            </Tooltip>
          </Box>
        </Box>
        <Button
          onClick={handleOpenPricingPlanDialog}
          variant="text"
          size="medium"
          sx={{
            backgroundColor: (theme) => theme.palette.grey[50],
            color: (theme) => theme.palette.grey[800],
            mr: 1,
            border: (theme) => `1px solid ${theme.palette.grey[50]}`,
            ":hover": {
              color: (theme) => theme.palette.grey[800],
              backgroundColor: (theme) => theme.palette.grey[50],
            },
            textTransform: "none",
          }}
          startIcon={<BoltIcon sx={{ color: "#ebac6e" }} />}
        >
          Upgrade
        </Button>
        <AccountMenu />
      </Toolbar>
      <PricingPlaneDialog
        handleOpenPricingPlanDialog={handleOpenPricingPlanDialog}
        open={openPricingPlanDialog}
      />
    </AppBar>
  );
}
