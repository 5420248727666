import React from "react";

import Box from "@mui/material/Box";
import { CssBaseline } from "@mui/material";

import Episode from "../modules/Episode";

export default function EpisodePage() {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <CssBaseline />
      <Episode />
    </Box>
  );
}
