export default function calculateUsedTimeInPercentage(
  totalMinutes: number,
  availableTimeInSeconds: number,
) {
  const totalTimeInSeconds = totalMinutes * 60;

  const calucatedPrecentage =
    totalTimeInSeconds > 0
      ? ((totalTimeInSeconds - availableTimeInSeconds) / totalTimeInSeconds) *
        100
      : 0;

  return totalMinutes - Number(calucatedPrecentage.toFixed(2));
}
